'use strict';

/* libs */
import svg4everybody from 'svg4everybody';

/* custom imports */
import NativeConsole from './modules/native-console';
import Navigation from './modules/navigation';

/**
 * Chilli App
 * @class
 * @author Thomas Van Kerckvoorde <thomas@chilli.be>
 */

class ChilliApp{

	/**
	 * @constructor
	 */
	constructor(){
		new NativeConsole();
		new Navigation();

		/* external svg polyfill, https://github.com/jonathantneal/svg4everybody */
		svg4everybody();

		if (document.querySelector('.logo:not(.no-sticky)')) {

			window.addEventListener('scroll', function () {
				if( window.window.pageYOffset > 245 ){
					document.querySelector('.logo:not(.no-sticky)').classList.add('sticky');
				}else{
					document.querySelector('.logo:not(.no-sticky)').classList.remove('sticky');
				}
			});
		}

	}
}

/**
 * js vanilla equivalent to jquerys $(document).ready -- fires when DOM is ready (assets not yet ready)
 */
window.addEventListener('load', function() {
	new ChilliApp();
});
