'use strict';

export default class Navigation {
	constructor() {
		// polyfill for foreach on a node list for ie and Edge
		if (window.NodeList && !NodeList.prototype.forEach) {
			NodeList.prototype.forEach = function(callback, thisArg) {
				thisArg = thisArg || window;
				for (var i = 0; i < this.length; i++) {
					callback.call(thisArg, this[i], i, this);
				}
			};
		}

		// get our hamburger button and make it accessible in this module.
		this.navButton = document.querySelectorAll('.js-nav');
		// get our navigation block and make it accessible in this module.
		this.menu = document.querySelector('#navigation');

		this.stateOpen = 0;

		// attach handlers
		this.attachHandlers();
	}

	attachHandlers() {
		document.onkeydown = evt => {
			evt = evt || window.event;
			if (evt.keyCode == 27 && this.stateOpen) {
				this.closeNavigation();
			}
		};

		document.addEventListener('click', e => {
			if (this.stateOpen) {
				this.closeNavigation();
			}
		});

		this.navButton.forEach((el, i, arr) => {
			el.addEventListener('click', e => {
				// this prevents the click from bubbling up to the body,
				// so it doesnt close the navigation immediately because we have a click on the body for closing the nav.
				e.stopPropagation();

				this.stateOpen = !this.stateOpen;

				// aria toggle for accessibilty
				this.navButton.forEach((el, i, arr) => {
					el.setAttribute(
						'aria-expanded',
						!(el.getAttribute('aria-expanded') == 'true')
					);
				});

				// toggle active states
				setTimeout(() => {
					this.navButton.forEach((el, i, arr) => {
						el.classList.toggle('is-active');
					});
				}, 0);

				// open the menu
				this.toggleDrawer();
			});
		});
	}

	closeNavigation() {
		this.toggleDrawer();
		this.navButton.forEach((el, i, arr) => {
			el.setAttribute(
				'aria-expanded',
				!(el.getAttribute('aria-expanded') == 'true')
			);
		});

		// toggle active states
		setTimeout(() => {
			this.navButton.forEach((el, i, arr) => {
				el.classList.toggle('is-active');
			});
		}, 0);

		this.stateOpen = 0;
	}

	toggleDrawer() {
		// define classes that need to be toggled
		// let first = 'nav-trans';
		// let second = 'nav-op';

		// switch classes order when moving out of the screen
		// if(this.menu.classList.contains(first)) {
		// 	[first, second] = [second, first];
		// }

		// toggle first class
		//this.menu.classList.toggle(first);

		// toggle second after timeout
		// setTimeout(() => {
		// 	this.menu.classList.toggle(second);
		// }, 200);

		// add extra class for other styling purposes
		this.menu.classList.toggle('navigation--expanded');

		// lock and unlock body
		document.querySelector('body').classList.toggle('locked');
	}
}
